import { html, css, LitElement } from 'lit';
import { createRef, ref } from 'lit/directives/ref.js';

/* eslint-disable no-useless-constructor */
export class DNPCircularItem extends LitElement {
	static styles = css`
		:host {
			display: block;
			position: relative;
		}

		#media {
			width: 100%;
			height: 87%;
			overflow: hidden;
		}

		#content {
			position: absolute;
			right: 0;
			bottom: 0;
			left: 0;
			padding: 0.3rem var(--dnp-circular-cover-padding, 1rem);
		}

		#content > slot::slotted(*) {
			margin: 0;
		}

		#toolbar {
			position: absolute;
			top: 2.5rem;
			right: 1.2rem;
		}

		#controls {
			display: flex;
			justify-content: space-between;
		}
	`;

	constructor() {
		super();
		this.controlsRef = createRef();
	}

	get controls() {
		return this.controlsRef?.value;
	}

	render() {
		return html`
			<div id="media">
				<slot name="media"></slot>
			</div>
			<div id="content">
				<div id="controls">
					<dnp-circular-controls
						${ref(this.controlsRef)}
					></dnp-circular-controls>
					<slot name="more"></slot>
				</div>
				<slot></slot>
			</div>
			<div id="toolbar">
				<slot name="toolbar"></slot>
			</div>
		`;
	}
}

window.customElements.define('dnp-circular-item', DNPCircularItem);
