import { css } from 'lit';

export const formStyles = css`
	form {
		border: var(--dnp-newsletter-form-border, 1px solid #ddd);
		background: var(--dnp-newsletter-form-background, #f2f2f2);
		padding: var(--dnp-newsletter-form-padding, 0.75em 0.75em 2rem 0.75em);
		width: var(--dnp-newsletter-form-width, 100%);
		width: var(--dnp-newsletter-form-max-width, 100%);
		border-radius: var(--dnp-newsletter-form-border-radius, 0rem);
		animation: var(--dnp-newsletter-form-animation, inherit);
		transform: var(--dnp-newsletter-form-transform, inherit);
		transition: var(--dnp-newsletter-form-transition, inherit);
		opacity: var(--dnp-newsletter-form-opacity, 1);
		font-family: var(--dnp-newsletter-form-font-family, inherit);
		font-size: var(--dnp-newsletter-form-font-size, inherit);
		overflow: var(--dnp-newsletter-form-overflow, inherit);
		box-sizing: var(--dnp-newsletter-form-box-sizing, border-box);
		position: var(--dnp-newsletter-form-position, border-box);
		z-index: var(--dnp-newsletter-form-z-index, inherit);
		display: var(--dnp-newsletter-form-display, grid);
		grid-template-columns: var(
			--dnp-newsletter-form-grid-template-column,
			repeat(2, 1fr)
		);
		grid-template-rows: var(--dnp-newsletter-form-grid-template-rows, auto);
		gap: var(--dnp-newsletter-form-gap, 1rem);
	}

	h5 {
		background: var(--dnp-newsletter-title-background, inherit);
		color: var(--dnp-newsletter-title-color, inherit);
		font-style: var(--dnp-newsletter-title-font-style, inherit);
		font-size: var(--dnp-newsletter-title-font-size, 1.25rem);
		font-weight: var(--dnp-newsletter-title-font-weight, inherit);
		font-family: var(--dnp-newsletter-title-font-family, inherit);
		box-sizing: var(--dnp-newsletter-title-box-sizing, border-box);
		text-decoration: var(--dnp-newsletter-title-text-decoration, inherit);
		letter-spacing: var(--dnp-newsletter-title-letter-spacing, inherit);
		padding: var(--dnp-newsletter-title-padding, 0);
		margin: var(--dnp-newsletter-title-margin, 0);
		position: var(--dnp-newsletter-title-position, inherit);
		text-rendering: var(
			--dnp-newsletter-title-rendering,
			optimizeLegibility
		);
		outline: var(--dnp-newsletter-title-outline, 0);
		grid-column: var(--dnp-newsletter-title-grid-column, 1 / 3);
		grid-row: var(--dnp-newsletter-title-grid-row, auto);
		display: var(--dnp-newsletter-title-display, inherit);
	}

	input[type="text"],
	input[type="email"],
	select {
		min-height: var(--dnp-newsletter-input-min-height, 32px);
		height: var(--dnp-newsletter-input-height, 34px);
		padding: var(--dnp-newsletter-input-padding, 8px 16px);
		margin: var(--dnp-newsletter-input-margin, 0);
		line-height: var(--dnp-newsletter-input-line-height, 1.42);
		color: var(--dnp-newsletter-input-line-height, #555);
		background: var(--dnp-newsletter-input-line-height, #fff);
		border: var(--dnp-newsletter-input-line-height, 1px solid #ccc);
		border-radius: var(--dnp-newsletter-input-border-radius, 2px);
		box-sizing: var(--dnp-newsletter-input-box-sizing, border-box);
		text-shadow: var(--dnp-newsletter-input-text-shadow, none);
		outline: var(--dnp-newsletter-input-outline, 0);
		width: var(--dnp-newsletter-input-outline, 100%);
	}

	button {
		border: var(--dnp-newsletter-button-border, none);
		background: var(--dnp-newsletter-button-background, #181716);
		color: var(--dnp-newsletter-button-color, #f2f2f2);
		cursor: var(--dnp-newsletter-button-cursor, pointer);
		appearance: var(--dnp-newsletter-button-appearance, none);
		padding: var(--dnp-newsletter-button-padding, 8px 16px);
		margin: var(--dnp-newsletter-button-margin, 0.5rem 0);
		line-height: var(--dnp-newsletter-button-line-height, 1.42);
		text-align: var(--dnp-newsletter-button-text-align, center);
		white-space: var(--dnp-newsletter-button-white-space, nowrap);
		border-radius: var(--dnp-newsletter-button-border-radius, 2px);
		text-shadow: var(--dnp-newsletter-button-text-shadow, none);
		font: var(--dnp-newsletter-button-font, inherit);
		transition: var(--dnp-newsletter-button-transition, none);
	}

	button:hover {
		color: var(--dnp-newsletter-button-hover-color, #000);
		background-color: var(--dnp-newsletter-button-hover-background-color, #d9d9d9);
		border-color: var(--dnp-newsletter-button-hover-border-color, #b3b3b3);
		font: var(--dnp-newsletter-button-hover-font, inherit);
		transition: var(--dnp-newsletter-hover-button-transition, none);
		opacity: var(--dnp-newsletter-button-active-hover-opacity, 0.9);
	}

	button:active {
		opacity: var(--dnp-newsletter-button-active-opacity, 0.9);
	}

	button svg {
		height: 30px;
	}

	input[name='name'] {
		grid-column: var(--dnp-newsletter-input-name-grid-column, 1 / 2);
		grid-row: var(--dnp-newsletter-input-name-grid-row, auto);
	}

	input[name='last-name'] {
		grid-column: var(--dnp-newsletter-input-last-name-grid-column, 2 / 3);
		grid-row: var(--dnp-newsletter-input-last-name-grid-row, auto);
	}

	select[name='country'] {
		grid-column: var(--dnp-newsletter-input-country-grid-column, 1 / 3);
		grid-row: var(--dnp-newsletter-input-country-grid-row, auto);
	}

	input[name='city'] {
		grid-column: var(--dnp-newsletter-input-city-grid-column, 1 / 2);
		grid-row: var(--dnp-newsletter-input-city-grid-row, auto);
	}

	input[name='state'] {
		grid-column: var(--dnp-newsletter-input-last-state-grid-column, 2 / 3);
		grid-row: var(--dnp-newsletter-input-last-state-grid-row, auto);
	}

	input[name='street'] {
		grid-column: var(--dnp-newsletter-input-street-grid-column, 1 / 2);
		grid-row: var(--dnp-newsletter-input-street-grid-row, auto);
	}

	input[name='zip'] {
		grid-column: var(--dnp-newsletter-input-last-zip-grid-column, 2 / 3);
		grid-row: var(--dnp-newsletter-input-last-zip-grid-row, auto);
	}

	input[name='email'] {
		grid-column: var(--dnp-newsletter-input-email-grid-column, 1 / 3);
		grid-row: var(--dnp-newsletter-input-email-grid-row, auto);
	}

	input[name='company'] {
		grid-column: var(--dnp-newsletter-input-company-grid-column, 1 / 3);
		grid-row: var(--dnp-newsletter-input-company-grid-row, auto);
	}

	select[name='industry'] {
		grid-column: var(--dnp-newsletter-input-industry-grid-column, 1 / 3);
		grid-row: var(--dnp-newsletter-input-industry-grid-row, auto);
	}

	select[name='job'] {
		grid-column: var(--dnp-newsletter-input-job-grid-column, 1 / 3);
		grid-row: var(--dnp-newsletter-input-job-grid-row, auto);
	}

	.accept {
		grid-column: var(--dnp-newsletter-accept-grid-column, 1 / 3);
		grid-row: var(--dnp-newsletter-accept-grid-row, auto);
	}

	.accept-message {
		font-weight: var(--dnp-newsletter-accept-message-font-weight, bold);
		display: var(--dnp-newsletter-accept-message-display, block);
		margin: var(--dnp-newsletter-accept-message-margin, 0.4rem 0);
		font-size: var(--dnp-newsletter-accept-message-font-size, 0.9rem);
	}

	.accept-label {
		display: var(--dnp-newsletter-accept-label-display, flex);
		align-items: var(--dnp-newsletter-accept-label-align-items, center);
	}

	.success {
		background: var(--dnp-newsletter-success-background, #468847);
		color: var(--dnp-newsletter-success-color, #f2f2f2);
		padding: var(--dnp-newsletter-success-padding, 0.75rem);
		margin: var(--dnp-newsletter-success-margin, 0.75em auto);
		border: var(--dnp-newsletter-success-border, inherit);
		display: var(--dnp-newsletter-success-display, block);
	}

	.error {
		background: var(--dnp-newsletter-error-background, #cd5c5c);
		color: var(--dnp-newsletter-error-color, #f2f2f2);
		padding: var(--dnp-newsletter-error-padding, 0.75rem);
		margin: var(--dnp-newsletter-error-margin, 0.75em auto);
		border: var(--dnp-newsletter-error-border, inherit);
		display: var(--dnp-newsletter-error-display, block);
		grid-column: var(--dnp-newsletter-error-grid-column, 1 / 3);
		grid-row: var(--dnp-newsletter-error-grid-row, auto);
		width: var(--dnp-newsletter-error-width, 100%);
		box-sizing: var(--dnp-newsletter-error-box-sizing, border-box);
		border-radius: var(--dnp-newsletter-error-border-radius, inherit);
	}

	.error a {
		color: var(--dnp-newsletter-error-color, #f2f2f2);
	}

	.download {
		display: var(--dnp-newsletter-download-display, inherit);
		justify-self: var(--dnp-newsletter-download-justify-self, left);
		padding: var(--dnp-newsletter-download-padding, 12px);
		margin: var(--dnp-newsletter-download-margin, 12px 0px);
	}

	.button-download {
		display: var(--dnp-newsletter-button-download-display, inherit);
		box-sizing: var(
			--dnp-newsletter-button-download-box-sizing,
			border-box
		);
		color: var(--dnp-newsletter-button-download-box-color, #f2f2f2);
		background: var(--dnp-newsletter-button-download-box-background, #333);
		padding: var(--dnp-newsletter-button-download-box-padding, 0.75em);
		margin: var(--dnp-newsletter-button-download-box-margin, 0.25em 0);
		transition: var(
			--dnp-newsletter-button-download-box-transition,
			all 0.3s ease
		);
		text-decoration: var(
			--dnp-newsletter-button-download-box-text-decoration,
			none
		);
	}

	.button-download:hover {
		opacity: var(--dnp-newsletter-button-download-hover-opacity, 0.7);
	}
`;
