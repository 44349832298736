import { html, LitElement } from 'lit';

/* eslint-disable no-useless-constructor */
export class DNPAsideNews extends LitElement {
	render() {
		return html`
			<div>
				<slot />
			</div>
		`;
	}
}

window.customElements.define('dnp-aside-news', DNPAsideNews);
