import './shared/dnp-circular-loader';
import './shared/dnp-modal';
import './blocks/circular/dnp-circular-cover';
import './blocks/circular/dnp-circular-progress';
import './blocks/circular/dnp-circular-controls';
import './blocks/circular/dnp-circular-item';
import './blocks/circular/dnp-circular-logo';
import './blocks/circular/dnp-circular-viewer';
import './blocks/newsletter/dnp-newsletter-block';
import './blocks/related/dnp-related-news';
import './blocks/newsletter/form/dnp-newsletter-form';
import './blocks/newsletter/dnp-newsletter-register';
import './blocks/newsletter/dnp-newsletter-download';
