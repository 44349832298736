import { html, css, LitElement } from 'lit';

/* eslint-disable no-useless-constructor */
export class DNPCircularCover extends LitElement {
	static styles = css`
		:host {
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			background: rgba(0, 0, 0, 0.6);
			display: flex;
			justify-content: center;
			align-items: center;
			--dnp-pulse-init: 0 0 0 0 rgba(255, 255, 255, 0.4);
			--dnp-pulse-medium: 0 0 0 13px rgba(255, 255, 255, 0);
			--dnp-pulse-end: 0 0 0 0 rgba(255, 255, 255, 0);
		}

		figure {
			margin: 0;
			padding: 0;
		}

		svg {
			fill: #ffffff;
			width: 100px;
			height: 100px;
			position: absolute;
			top: 0;
			left: 0;
		}

		.pulse {
			display: block;
			width: 100px;
			height: 100px;
			border-radius: 50%;
			cursor: pointer;
			box-shadow: 0 0 0 rgba(204, 169, 44, 0.6);
			animation: pulse 2s infinite;
			position: relative;
		}

		button {
			padding: 0;
			margin: 0;
			background: transparent;
			outline: none;
			width: 100px;
			height: 100px;
			border: none;
			position: relative;
			cursor: pointer;
		}

		button:active {
			opacity: 0.8;
		}

		@-webkit-keyframes pulse {
			0% {
				-webkit-box-shadow: var(--dnp-pulse-init);
			}
			70% {
				-webkit-box-shadow: var(--dnp-pulse-medium);
			}
			100% {
				-webkit-box-shadow: var(--dnp-pulse-end);
			}
		}

		@keyframes pulse {
			0% {
				-moz-box-shadow: var(--dnp-pulse-init);
				box-shadow: var(--dnp-pulse-init);
			}
			70% {
				-moz-box-shadow: var(--dnp-pulse-medium);
				box-shadow: var(--dnp-pulse-medium);
			}
			100% {
				-moz-box-shadow: var(--dnp-pulse-end);
				box-shadow: var(--dnp-pulse-end);
			}
		}
	`;

	play() {
		const eventPrev = new window.CustomEvent('dnpcircularplay', {
			bubbles: true,
			composed: true,
		});
		this.dispatchEvent(eventPrev);
	}

	render() {
		return html`
			<figure class="pulse">
				<button type="button" @click=${this.play}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						xmlns:xlink="http://www.w3.org/1999/xlink"
						style="isolation:isolate"
						viewBox="0 0 150 150"
						width="150pt"
						height="150pt"
					>
						<defs>
							<clipPath
								id="_clipPath_CB2BEXUF5hITiILwFPEHmTXJMVOS4Xbn"
							>
								<rect width="150" height="150" />
							</clipPath>
						</defs>
						<g
							clip-path="url(#_clipPath_CB2BEXUF5hITiILwFPEHmTXJMVOS4Xbn)"
						>
							<circle
								vector-effect="non-scaling-stroke"
								cx="75"
								cy="74.99999999999997"
								r="74"
								fill="rgb(255,255,255)"
							/>
							<path
								d=" M 104 75 L 55 107 L 55 43 L 104 75 Z "
								fill-rule="evenodd"
								fill="rgb(0,0,0)"
							/>
						</g>
					</svg>
				</button>
			</figure>
		`;
	}
}

window.customElements.define('dnp-circular-cover', DNPCircularCover);
