import { html, css, LitElement } from 'lit';

const icons = {
	prev: html`<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		style="isolation:isolate"
		viewBox="0 0 100 100"
		width="100pt"
		height="100pt"
	>
		<defs>
			<clipPath id="_clipPath_bfyd07U0cOfMWHfelHkLERr11YpZyfDE">
				<rect width="100" height="100" />
			</clipPath>
		</defs>
		<g clip-path="url(#_clipPath_bfyd07U0cOfMWHfelHkLERr11YpZyfDE)">
			<g>
				<path
					d=" M 98.344 1.844 L 29.281 50.594 L 98.344 99.344 L 98.344 1.844 Z M 17.094 99.344 L 17.094 1.844 L 0.844 1.844 L 0.844 99.344 L 17.094 99.344 Z "
				/>
				<path
					d=" M 141 142 L -54 142 L -54 -53 L 141 -53 L 141 142 Z "
					fill="none"
				/>
			</g>
		</g>
	</svg>`,
	pause: html`<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		style="isolation:isolate"
		viewBox="0 0 100 100"
		width="100pt"
		height="100pt"
	>
		<defs>
			<clipPath id="_clipPath_OHFA61dT2V6knZOikrUg7JHQqVaQfNtn">
				<rect width="100" height="100" />
			</clipPath>
		</defs>
		<g clip-path="url(#_clipPath_OHFA61dT2V6knZOikrUg7JHQqVaQfNtn)">
			<g>
				<path
					d=" M 8 99 L 36 99 L 36 1 L 8 1 L 8 99 Z  M 64 1 L 64 99 L 92 99 L 92 1 L 64 1 Z "
				/>
				<path
					d=" M -34 -34 L 134 -34 L 134 134 L -34 134 L -34 -34 Z "
					fill="none"
				/>
			</g>
		</g>
	</svg>`,
	next: html`<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		style="isolation:isolate"
		viewBox="0 0 100 100"
		width="100pt"
		height="100pt"
	>
		<defs>
			<clipPath id="_clipPath_Hj4wVseCKXbF0rXE2oYRINpb1ki5wWsm">
				<rect width="100" height="100" />
			</clipPath>
		</defs>
		<g clip-path="url(#_clipPath_Hj4wVseCKXbF0rXE2oYRINpb1ki5wWsm)">
			<g>
				<path
					d=" M 1.656 99.156 L 70.719 50.406 L 1.656 1.656 L 1.656 99.156 Z  M 82.906 1.656 L 82.906 99.156 L 99.156 99.156 L 99.156 1.656 L 82.906 1.656 Z "
				/>
				<path
					d=" M -41 -41 L 154 -41 L 154 154 L -41 154 L -41 -41 Z "
					fill="none"
				/>
			</g>
		</g>
	</svg>`,
	play: html`<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		style="isolation:isolate"
		viewBox="0 0 100 100"
		width="100"
		height="100"
	>
		<defs>
			<clipPath id="_clipPath_Ylx8MRojHXKmbZGcFrU3xHlCtgOWaI2O">
				<rect width="100" height="100" />
			</clipPath>
		</defs>
		<g clip-path="url(#_clipPath_Ylx8MRojHXKmbZGcFrU3xHlCtgOWaI2O)">
			<path
				d=" M 99 50.08 L 2 98.5 L 2 1.5 L 99 50.08 Z "
				fill-rule="evenodd"
			/>
		</g>
	</svg>`,
};

/* eslint-disable no-useless-constructor */
export class DNPCircularControls extends LitElement {
	static styles = css`
		:host {
			align-items: center;
			width: 100%;
		}

		section {
			display: inline-flex;
			gap: 0.5rem;
			flex: 1;
		}

		svg {
			width: 20px;
			height: 20px;
			fill: var(--dnp-circular-controls-color, #fff);
		}

		.control {
			border: none;
			outline: none;
			background: transparent;
			cursor: pointer;
			width: 37px;
			height: 37px;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.control:hover {
			background: var(
				--dnp-circular-controls-background-cover,
				rgba(255, 255, 255, 0.4)
			);
		}

		.control:active {
			transform: scale(0.9);
		}
	`;

	static properties = {
		playing: {
			type: Boolean,
			reflect: true,
		},
	};

	constructor() {
		super();
		this.playing = true;
		this.more = 'Read More';
	}

	#sendCustomEvent(name) {
		const eventPrev = new window.CustomEvent(name, {
			bubbles: true,
			composed: true,
		});
		this.dispatchEvent(eventPrev);
	}

	prev() {
		this.#sendCustomEvent('dnpcircularprev');
	}

	next() {
		this.#sendCustomEvent('dnpcircularnext');
	}

	pause() {
		this.playing = false;
		this.#sendCustomEvent('dnpcircularpause');
	}

	play() {
		this.playing = true;
		this.#sendCustomEvent('dnpcircularplay');
	}

	render() {
		return html`
			<section>
				<button class="control" type="button" @click=${this.prev}>
					${icons.prev}
				</button>
				<button
					class="control"
					type="button"
					@click=${this.playing ? this.pause : this.play}
				>
					${this.playing ? icons.pause : icons.play}
				</button>
				<button class="control" type="button" @click=${this.next}>
					${icons.next}
				</button>
			</section>
		`;
	}
}

window.customElements.define('dnp-circular-controls', DNPCircularControls);
