export const IntersectionObserverMixin = (SuperClass) => {
	return class extends SuperClass {
		constructor() {
			super();
			this.elementVisible = false;
			this.IOThresholds = [0.0, 0.25, 0.5, 0.75, 1.0];
			this.IORootMargin = '0px';
			this.IOVisibleLimit = 0.5;
			this.IORemoveOnVisible = true;
			this.IODelay = 100;
		}

		static get properties() {
			let props = {};
			if (super.properties) {
				props = super.properties;
			}
			return {
				...props,
				elementVisible: {
					type: Boolean,
					attribute: 'element-visible',
					reflect: true,
				},
			};
		}

		connectedCallback() {
			if (super.connectedCallback) {
				super.connectedCallback();
			}
			if (!this.elementVisible) {
				this.intersectionObserver = new window.IntersectionObserver(
					this.handleIntersectionCallback.bind(this),
					{
						root: document.rootElement,
						rootMargin: this.IORootMargin,
						threshold: this.IOThresholds,
						delay: this.IODelay,
					}
				);
				this.intersectionObserver.observe(this);
			}
		}
		disconnectedCallback() {
			if (this.intersectionObserver) {
				this.intersectionObserver.disconnect();
			}
			if (super.disconnectedCallback) {
				super.disconnectedCallback();
			}
		}
		handleIntersectionCallback(entries) {
			for (const entry of entries) {
				const ratio = Number(entry.intersectionRatio).toFixed(2);
				if (ratio >= this.IOVisibleLimit) {
					this.elementVisible = true;
					if (this.IORemoveOnVisible) {
						this.intersectionObserver.disconnect();
					}
				}
			}
		}
	};
};
