import { html, LitElement } from 'lit';
import { NEWSLETTER_FORM_DOWNLOAD } from '../../../wordpress/helpers/newsletter';
import DownloadService from '../../services/download-service';

export class DNPNewsetterDownload extends LitElement {
	static properties = {
		origin: { type: String, reflect: true },
		options: { type: Object },
	};

	#service = new DownloadService();

	async firstUpdated() {
		if (!this.options) {
			this.options = await this.#service.getOptions(this.origin);
		}
	}

	render() {
		if (!this.options) {
			return null;
		}

		return html`
			<dnp-newsletter-form
				origin=${this.origin}
				type=${NEWSLETTER_FORM_DOWNLOAD}
				.options=${this.options}
			></dnp-newsletter-form>
		`;
	}
}

window.customElements.define('dnp-newsletter-download', DNPNewsetterDownload);
