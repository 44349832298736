import { html, css } from 'lit';
import { LitElementWithCircular } from '../../mixins/classes';

/* eslint-disable no-useless-constructor */
export class DNPCircularViewer extends LitElementWithCircular {
	_interval = null;
	currentImage = null;
	currentItem = null;

	_time = 5500;

	static styles = css`
		:host {
			display: block;
			position: relative;
			border: none;
			height: 100%;
			width: 100%;
			display: flex;
		}

		::slotted(*) {
			width: 100%;
			height: 100%;
			will-change: transform;
			cursor: pointer;
			user-select: none;
			min-width: 100%;
			position: absolute;
		}

		dnp-circular-loader {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		dnp-circular-progress {
			position: absolute;
			top: 0.8rem;
			left: 0.8rem;
			right: 0.8rem;
			z-index: 20;
		}

		dnp-circular-cover {
			z-index: 21;
		}

		dnp-circular-logo {
			position: absolute;
			top: 2rem;
			left: 0.9rem;
			z-index: 20;
		}
	`;

	static properties = {
		progresscolor: { type: String, reflect: true },
		logoimageurl: { type: String, reflect: true },
		logotitle: { type: String, reflect: true },
		_paused: { type: Boolean, state: true },
		_loading: { type: Boolean, state: true },
		_selected: { type: Number, state: true },
	};

	constructor() {
		super();
		this.autoplay = true;
		this._loading = true;
		this._selected = 0;
		this._paused = false;
	}

	async _initFirstTime() {
		await this.readyForInitilization();
		await this.preloadListImage(this.getImagesFromChildren(this.children));

		this._loading = false;
		this._initialDate = new Date();
		this._continueTimeout = null;
		this._continueTime = null;
		this.play();
	}

	disconnectedCallback() {
		super.disconnectedCallback();
		clearInterval(this._interval);
	}

	async update(changedProperties) {
		super.update(changedProperties);
		if (this._loading) {
			await this._initFirstTime();
		}
		this.proccess(this.children, this._selected);
	}

	play() {
		clearInterval(this._interval);
		clearTimeout(this._continueTimeout);
		this._paused = false;
		this.playAnimation();
		this.playProgress();

		if (this._continueTime) {
			this._continueTimeout = setTimeout(
				() => this.next(),
				this._continueTime
			);
			this._continueTime = null;
		} else {
			this._interval = setInterval(() => this.next(), this._time);
		}
	}

	prev() {
		this._initialDate = new Date();
		this._selected = this.shiftToPrev(this.children, this._selected);
		this.play();
	}

	next() {
		this._initialDate = new Date();
		this._selected = this.shiftToNext(this.children, this._selected);
		this.play();
	}

	pause() {
		const now = new Date();
		const diff = now.getTime() - this._initialDate.getTime();

		if (diff < this._time) {
			this._continueTime = this._time - diff;
		}

		clearInterval(this._interval);
		clearTimeout(this._continueTimeout);
		this._paused = true;
		this.pauseAnimation();
		this.pauseProgress();
	}

	render() {
		if (this._loading) {
			return html`<dnp-circular-loader></dnp-circular-loader>`;
		}

		return html`
			<slot
				@dnpcircularnext=${this.next}
				@dnpcircularprev=${this.prev}
				@dnpcircularpause=${this.pause}
				@dnpcircularplay=${this.play}
			></slot>
			<dnp-circular-progress
				total=${this.children.length}
				index=${this._selected}
				color=${this.progresscolor}
			></dnp-circular-progress>

			<dnp-circular-logo
				progresscolor="${this.progresscolor}"
				logoimageurl="${this.logoimageurl}"
				logotitle="${this.logotitle}"
			></dnp-circular-logo>

			${this._paused
				? html`<dnp-circular-cover
						@dnpcircularplay=${this.play}
				  ></dnp-circular-cover>`
				: null}
		`;
	}
}

window.customElements.define('dnp-circular-viewer', DNPCircularViewer);
