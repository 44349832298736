import { html, css, LitElement } from 'lit';
import { when } from 'lit/directives/when.js';
import { styleMap } from 'lit/directives/style-map.js';

/* eslint-disable no-useless-constructor */
export class DNPCircularLogo extends LitElement {
	static styles = css`
		:host {
			display: flex;
			align-items: center;
			gap: 0.7rem;
		}

		span {
			color: #fff;
			padding: 0.1rem 0.5rem;
			font-size: 0.95rem;
			font-weight: bold;
		}
	`;

	static properties = {
		progresscolor: { type: String, reflect: true },
		logoimageurl: { type: String, reflect: true },
		logotitle: { type: String, reflect: true },
	};

	constructor() {
		super();
		this.templateImage = this.templateImage.bind(this);
		this.templateTitle = this.templateTitle.bind(this);
	}

	templateImage() {
		return html`<img src="${this.logoimageurl}" width="48" height="48" />`;
	}

	templateTitle() {
		return html`<span
			style="${styleMap({ background: this.progresscolor })}"
			>${this.logotitle}</span
		>`;
	}

	render() {
		return html`
			${when(this.logoimageurl, this.templateImage)}
			${when(this.logotitle, this.templateTitle)}
		`;
	}
}

window.customElements.define('dnp-circular-logo', DNPCircularLogo);
