import { createCookie, getCookie } from '../../wordpress/helpers/cookie';

class RegisterService {
	#cookieName = 'dnp_cookie_expired_modal';

	async getOptions(origin) {
		const response = await window.fetch(
			`${origin}/wp-json/dreamsenginenewspack/v1/settings-register-form`
		);
		const data = await response.json();
		return data?.options;
	}

	shouldShowModal(optionValue) {
		if (!optionValue) {
			return false;
		}
		const cookie = getCookie(this.#cookieName);
		if (cookie) {
			return false;
		}

		return optionValue;
	}

	saveVisit(optionAfterTime) {
		const expireInMiliseconds = parseInt(optionAfterTime) || 0;
		createCookie({
			name: this.#cookieName,
			value: true,
			expireInMiliseconds,
		});
	}
}

export default RegisterService;
