class DownloadService {
	async getOptions(origin) {
		const response = await window.fetch(
			`${origin}/wp-json/dreamsenginenewspack/v1/settings-download-form`
		);
		const data = await response.json();
		return data?.options;
	}
}

export default DownloadService;
