import { html, LitElement } from 'lit';
import { NEWSLETTER_FORM_REGISTER } from '../../../wordpress/helpers/newsletter';
import { IntersectionObserverMixin } from '../../mixins/interception-observer';
import RegisterService from '../../services/register-service';

/* eslint-disable no-useless-constructor */
export class DNPNewsetterRegister extends IntersectionObserverMixin(
	LitElement
) {
	static properties = {
		origin: { type: String, reflect: true },
		options: { type: Object },
		showModal: { type: Boolean, state: true },
	};

	#service = new RegisterService();

	constructor() {
		super();
		this.showModal = false;
	}

	firstUpdated() {
		if (this.options) {
			this.showModal = this.#service.shouldShowModal(
				this.options.showModal
			);
		}
	}

	async updated() {
		if (this.elementVisible && !this.options) {
			this.options = await this.#service.getOptions(this.origin);
			this.showModal = this.#service.shouldShowModal(
				this.options.showModal
			);
		}
	}

	requestModalClose() {
		this.showModal = false;
		this.#service.saveVisit(this.options.showModalAfterTime);
	}

	render() {
		if (!(this.elementVisible && this.options)) {
			return null;
		}

		return html`
			<dnp-newsletter-form
				origin=${this.origin}
				type=${NEWSLETTER_FORM_REGISTER}
				.options=${this.options}
			></dnp-newsletter-form>
			<dnp-modal
				?visible=${this.showModal}
				@dnp-modal-close=${this.requestModalClose}
			>
				<dnp-newsletter-form
					origin=${this.origin}
					type=${NEWSLETTER_FORM_REGISTER}
					.options=${this.options}
				></dnp-newsletter-form>
			</dnp-modal>
		`;
	}
}

window.customElements.define('dnp-newsletter-register', DNPNewsetterRegister);
