import { html, css, LitElement } from 'lit';

/* eslint-disable no-useless-constructor */
export class DNPCircularProgress extends LitElement {
	static styles = css`
		:host {
			display: flex;
			gap: var(--dnp-circular-progress-gap, 6px);
			height: var(--dnp-circular-progress-height, 4px);
		}

		.bar {
			flex: 1;
			position: relative;
			height: var(--dnp-circular-progress-height, 4px);
		}

		.inner {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
		}

		.empty {
			background: var(
				--dnp-circular-progress-empty-color,
				rgba(255, 255, 255, 0.6)
			);
		}

		.fill {
			background: var(--dnp-circular-progress-fill-color, #000);
		}

		.current {
			transition: 6s linear all;
			animation: dnp-increase-with 6s linear;
			background: var(--dnp-circular-progress-fill-color, #000);
		}

		@keyframes dnp-increase-with {
			0% {
				right: 100%;
			}

			50% {
				right: 50%;
			}

			100% {
				right: 0%;
			}
		}
	`;

	static properties = {
		total: { type: Number, reflect: true },
		index: { type: Number, reflect: true },
		color: { type: String, reflect: true },
	};

	constructor() {
		super();
		this.current = null;
	}

	get items() {
		return [...Array(this.total).keys()];
	}

	get emptyTemplate() {
		return html` <div class="bar">
			<span class="inner empty"></span>
		</div>`;
	}

	get fillTemplate() {
		return html` <div class="bar">
			<span class="inner fill" style="background: ${this.color}"></span>
		</div>`;
	}

	get currentTemplate() {
		return html` <div class="bar">
			<span class="inner empty"></span>
			<span
				class="inner current"
				style="background: ${this.color}"
			></span>
		</div>`;
	}

	getCurrent() {
		return this.shadowRoot.querySelector('.current');
	}

	getTemplate(position, index) {
		if (position === index) {
			return this.currentTemplate;
		}
		return position < index ? this.fillTemplate : this.emptyTemplate;
	}

	render() {
		return html`
			${this.items.map((_el, i) => this.getTemplate(i, this.index))}
		`;
	}
}

window.customElements.define('dnp-circular-progress', DNPCircularProgress);
