export function createCookie({ name, value, expireInMiliseconds = 0 }) {
	const date = new Date();
	date.setTime(date.getTime() + expireInMiliseconds);
	const expires = `;SameSite=Lax; expires=${date.toGMTString()}`;

	document.cookie = `${name}=${value}${expires}; path=/`;
}

export function getCookie(name) {
	const pattern = RegExp(`${name}=.[^;]`);
	const matched = document.cookie.match(pattern);
	if (matched) {
		const cookie = matched[0].split('=');
		return cookie[1];
	}
	return null;
}
