import { html, css, LitElement } from 'lit';

/* eslint-disable no-useless-constructor */
export class DNPModal extends LitElement {
	static properties = {
		visible: { type: Boolean, reflect: true },
	};

	static styles = css`
		.modal {
			position: var(--dnp-modal-position, fixed);
			top: var(--dnp-modal-top, 0);
			bottom: var(--dnp-modal-bottom, 0);
			left: var(--dnp-modal-left, 0);
			right: var(--dnp-modal-right, 0);
			background: var(--dnp-modal-background, rgba(0, 0, 0, 0.6));
			display: var(--dnp-modal-display, flex);
			justify-content: var(--dnp-modal-justify-content, center);
			align-items: var(--dnp-modal-align-items, center);
		}

		section {
			padding: var(--dnp-modal-slot-padding, 40px 25px 25px 25px);
			background: var(--dnp-modal-slot-background, rgb(237, 237, 237));
			max-width: var(--dnp-modal-slot-max-width, 500px);
			width: var(--dnp-modal-slot-width, 100%);
			width: var(--dnp-modal-slot-width, 100%);
			box-sizing: var(--dnp-modal-slot-box-sizing, border-box);
			position: var(--dnp-modal-slot-position, relative);
		}

		section span {
			position: var(--dnp-modal-close-position, absolute);
			right: var(--dnp-modal-close-right, 0);
			top: var(--dnp-modal-close-top, 0);
			text-align: var(--dnp-modal-close-text-align, center);
			padding: var(--dnp-modal-close-padding, 6px);
			cursor: var(--dnp-modal-close-cursor, pointer);
			-webkit-appearance: none;
			font-size: var(--dnp-modal-close-font-size, 28px);
			font-weight: var(--dnp-modal-close-font-weight, 700);
			line-height: var(--dnp-modal-close-line-height, 20px);
			color: var(--dnp-modal-close-top, #000);
		}

		section span:hover {
			opacity: var(--dnp-modal-close-hover-opacity, 0.5);
		}
	`;

	constructor() {
		super();
		this.visible = false;
	}

	firstUpdated() {
		window.addEventListener('keydown', (event) =>
			event.key === 'Escape' ? this.close() : undefined
		);
	}

	close() {
		this.dispatchEvent(new window.CustomEvent('dnp-modal-close'));
	}

	render() {
		if (!this.visible) {
			return null;
		}

		return html`
			<div class="modal">
				<section>
					<slot></slot>
					<span aria-label="close" @click=${this.close}>×</span>
				</section>
			</div>
		`;
	}
}

window.customElements.define('dnp-modal', DNPModal);
