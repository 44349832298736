import { html, LitElement } from 'lit';
import { ref, createRef } from 'lit/directives/ref.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { linearBars } from '../../../shared/svg/linear';
import { countryList } from '../../../../wordpress/helpers/countries';
import { formStyles } from './dnp-newsletter-form-styles';
import {
	NEWSLETTER_FORM_REGISTER,
	NEWSLETTER_FORM_DOWNLOAD,
} from '../../../../wordpress/helpers/newsletter';
import FormService from '../../../services/form-service';

/* eslint-disable no-useless-constructor */
export class DNPNewsetterForm extends LitElement {
	static properties = {
		type: { type: String, reflect: true },
		origin: { type: String, reflect: true },
		options: { type: Object },
		_success: { type: String, state: true },
		_error: { type: String, state: true },
		_loading: { type: Boolean, state: true },
	};

	#formRef = createRef();
	#service = new FormService();

	constructor() {
		super();
		this._loading = false;
	}

	static styles = formStyles;

	async handleOnSubmit(event) {
		event.preventDefault();

		//const form = this.#formRef.value;
		//console.log({ form })
		const form = this.#formRef.value;
		const email = form.email?.value;
		const fields = this.#service.getFields(form, this.options);
		this._success = null;
		this._error = null;

		try {
			this._loading = true;
			const data = await this.#service.send({
				origin: this.origin,
				email,
				fields,
			});

			if (this.#service.isRegisterContact(data)) {
				this._success = this.options?.messageSuccess ?? 'success';
			} else if (data?.message) {
				this._error = data?.message;
			}
		} catch (err) {
			this._error = this.options?.messageError ?? err.message;
		} finally {
			this._loading = false;
		}
	}

	render() {
		if (this._success && this.type === NEWSLETTER_FORM_REGISTER) {
			return html` <div class="success">
				<p>${this._success}</p>
			</div>`;
		}

		if (this._success && this.type === NEWSLETTER_FORM_DOWNLOAD) {
			return html` <form>
				<div class="download">
					<a
						class="button-download"
						href=${this.options.downloadUrl}
						target=${this.options.downloadOpenNewTab
							? '_blank'
							: '_self'}
					>
						${this.options.downloadMessageSend}
					</a>
				</div>
			</form>`;
		}

		return html`
			<form @submit=${this.handleOnSubmit} ${ref(this.#formRef)}>
				<h5>${this.options.messageTitle}</h5>
				${this.options.fieldNameAndLastnameShow
					? html`
							<input
								class="name"
								name="name"
								id="name"
								type="text"
								placeholder="${this.options
									.fieldNamePlaceholder}"
								?required=${this.options.fieldNameRequired}
							/>
							<input
								class="last-name"
								name="lastName"
								id="lastName"
								type="text"
								placeholder="${this.options
									.fieldLastnamePlaceholder}"
								?required=${this.options.fieldLastnameRequired}
							/>
					  `
					: null}
				${this.options.fieldCountryShow
					? html`
							<select
								class="country"
								name="country"
								id="country"
								?required=${this.options.fieldCountryRequired}
							>
								<option value="">
									${this.options.fieldCountryPlaceholder}
								</option>
								${countryList.map(
									(country) => html`
										<option value="${country.value}">
											${country.label}
										</option>
									`
								)}
							</select>
					  `
					: null}
				${this.options.fieldCityAndStateShow
					? html`
							<input
								class="city"
								name="city"
								id="city"
								type="text"
								placeholder="${this.options
									.fieldCityPlaceholder}"
								?required=${this.options.fieldCityRequired}
							/>
							<input
								class="state"
								name="state"
								id="state"
								type="text"
								placeholder="${this.options
									.fieldStatePlaceholder}"
								?required=${this.options.fieldStateRequired}
							/>
					  `
					: null}
				${this.options.fieldStreetAndZipShow
					? html`
							<input
								class="street"
								name="street"
								id="street"
								type="text"
								placeholder="${this.options
									.fieldStreetPlaceholder}"
								?required=${this.options.fieldStreetRequired}
							/>
							<input
								class="zip"
								name="zip"
								id="zip"
								type="text"
								placeholder="${this.options
									.fieldZipPlaceholder}"
								?required=${this.options.fieldZipRequired}
							/>
					  `
					: null}

					${this.options.fieldIndustryShow
						? html`
									<select
									class="industry"
									name="industry"
									id="industry"
									?required=${this.options.fieldIndustryRequired}
								>
									<option value="">${this.options.fieldIndustryPlaceholder}</option>
									${this.options.fieldIndustryValues.map(
										(industry) => html`
											<option value="${industry}">
												${industry}
											</option>
										`
									)}
								</select>
							`
						: null}


					${this.options.fieldCompanyShow
						? html`
								<input
									class="company"
									name="company"
									id="company"
									type="text"
									placeholder="${this.options
										.fieldCompanyPlaceholder}"
									?required=${this.options.fieldCompanyRequired ??
									true}
								/>
							`
						: null}


						${this.options.fieldJobShow
							? html`
										<select
										class="job"
										name="job"
										id="job"
										?required=${this.options.fieldJobRequired}
									>
										<option value="">${this.options.fieldJobPlaceholder}</option>
										${this.options.fieldJobValues.map(
											(job) => html`
												<option value="${job}">
													${job}
												</option>
											`
										)}
									</select>
								`
							: null}


							${this.options.fieldAcceptShow
								? html`
										<div class="accept" for="accept">
											<span class="accept-message">${this.options.fieldAcceptMessage}</span>
											<label class="accept-label">
												<input type="radio" id="accept" name="accept" value=${this.options.fieldAcceptCustomValue} ?required=${this.options.fieldAcceptRequired ?? false }  />
												${this.options.fieldAcceptPlaceholder}
											</label>
										</div>
									`
								: null}
				

				${this.options.fieldEmailShow
					? html`
							<input
								class="email"
								name="email"
								id="email"
								type="email"
								placeholder="${this.options
									.fieldEmailPlaceholder}"
								?required=${this.options.fieldEmailRequired ??
								true}
							/>
					  `
					: null}
				${this.options.messageSend
					? html`<button type="submit" ?disabled=${this._loading}>
							${this._loading
								? linearBars
								: this.options.messageSend}
					  </button>`
					: null}
				${this._error
					? html`<div class="error">
							<p>${unsafeHTML(this._error)}</p>
					  </div>`
					: null}
			</form>
		`;
	}
}

window.customElements.define('dnp-newsletter-form', DNPNewsetterForm);
