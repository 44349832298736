export const ImageMixin = (superClass) =>
	class extends superClass {
		preloadImage(url) {
			return new Promise((resolve) => {
				const img = new window.Image();
				img.src = url;
				img.onload = () => {
					resolve(url);
				};
			});
		}

		preloadListImage(images) {
			const promises = images.map((item) => this.preloadImage(item));
			return Promise.all(promises);
		}

		getImagesFromChildren() {
			return Array.from(this.children)
				.map((item) => item.querySelector('img')?.src)
				.filter((item) => item !== undefined);
		}
	};
