class FormService {
	async send({ origin, email, fields }) {
		const response = await window.fetch(
			`${origin}/wp-json/dreamsenginenewspack/v1/newsletter/suscribe`,
			{
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ email, fields }),
			}
		);
		const data = await response.json();
		return data;
	}

	getFields(form, options) {
		const {
			fieldNameCustomKey,
			fieldLastnameCustomKey,
			fieldCountryCustomKey,
			fieldCityCustomKey,
			fieldStateCustomKey,
			fieldStreetCustomKey,
			fieldZipCustomKey,
			fieldIndustryCustomKey,
			fieldCompanyCustomKey,
			fieldJobCustomKey,
			fieldAcceptCustomKey
		} = options;
		return {
			name: form.name?.value,
			lastName: form.lastName?.value,
			country: form.country?.value,
			city: form.city?.value,
			state: form.state?.value,
			street: form.street?.value,
			zip: form.zip?.value,
			industry: form.industry?.value,
			company: form.company?.value,
			job: form.job?.value,
			accept: form.accept?.checked ? form.accept?.value : undefined,
			fieldNameCustomKey,
			fieldLastnameCustomKey,
			fieldCountryCustomKey,
			fieldCityCustomKey,
			fieldStateCustomKey,
			fieldStreetCustomKey,
			fieldZipCustomKey,
			fieldIndustryCustomKey,
			fieldCompanyCustomKey,
			fieldJobCustomKey,
			fieldAcceptCustomKey
		};
	}

	isRegisterContact(data) {
		return (
			data?.id || data?.message.indexOf('is already a list member') > 0
		);
	}
}

export default FormService;
