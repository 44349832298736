import { html, css, LitElement } from 'lit';
import { createRef, ref } from 'lit/directives/ref.js';
import { linearBars } from '../../shared/svg/linear';

/* eslint-disable no-useless-constructor */
export class DNPNewsLetterBlock extends LitElement {
	static properties = {
		origin: { type: String, reflect: true },
		send: { type: String, reflect: true },
		success: { type: String, reflect: true },
		error: { type: String, reflect: true },
		_loading: { type: Boolean, state: true },
		_showSuccess: { type: Boolean, state: true },
		_showError: { type: Boolean, state: true },
	};

	static styles = css`
		.form {
			margin-top: 0.7rem;
			width: 100%;
		}

		.controls {
			display: flex;
			gap: 0.7rem;
		}

		.input {
			color: var(--dnp-newsletter-block-input-text-color, #000);
			background: var(
				--dnp-newsletter-block-input-background-color,
				#fff
			);
			border: var(--dnp-newsletter-block-input-border, 1px solid);
			outline: var(--dnp-newsletter-block-input-outline, none);
			padding: var(--dnp-newsletter-block-input-padding, 0 0.3rem);
		}

		.email {
			flex: 1;

			&:disabled {
				background: #f7f7f7;
			}
		}

		.send {
			color: var(--dnp-newsletter-btn-text-color, #fff);
			cursor: pointer;
			background: var(--dnp-newsletter-btn-background-color, #000);
			border: var(--dnp-newsletter-btn-border, none);
			outline: var(--dnp-newsletter-btn-outlin, none);
			padding: var(--dnp-newsletter-btn-padding, 0.5rem 1.2rem);
			display: flex;
			gap: 0.4rem;
		}

		.send:active {
			opacity: 0.9;
		}

		.send:disabled {
			opacity: 0.7;
		}

		.send svg {
			width: 20px;
			height: 20px;
			fill: #fff;
		}

		.messages {
			font-size: 0.8rem;
			margin-top: 0.4rem;
		}

		.success {
			color: green;
		}

		.error {
			color: rgb(111, 8, 8);
		}
	`;

	sendRef = createRef();
	emailRef = createRef();

	constructor() {
		super();
		this._loading = false;
		this._showSuccess = false;
		this._showError = false;
	}

	async handleSubmit(event) {
		event.preventDefault();
		this.hideMessages();
		this._loading = true;
		try {
			const response = await window.fetch(
				`${this.origin}/wp-json/dreamsenginenewspack/v1/newsletter/suscribe`,
				{
					method: 'POST',
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({ email: this.emailRef.value?.value }),
				}
			);
			const data = await response.json();

			if (
				data?.id ||
				data?.message.indexOf('is already a list member') > 0
			) {
				this.launchSuccces();
				this.emailRef.value.value = '';
			} else if (data?.message) {
				this.error = data?.message;
				this.launchError();
			}
		} catch (err) {
			this.error = err.message;
			this.launchError();
		} finally {
			this._loading = false;
		}
	}

	launchError() {
		this._showError = true;
		setTimeout(() => {
			this._showError = false;
		}, 6500);
	}

	launchSuccces() {
		this._showSuccess = true;
		setTimeout(() => {
			this._showSuccess = false;
		}, 6500);
	}

	hideMessages() {
		this._showSuccess = false;
		this._showError = false;
	}

	render() {
		return html`
			<form @submit=${this.handleSubmit} class="form">
				<div class="controls">
					<input
						class="input email"
						type="email"
						required
						${ref(this.emailRef)}
						?disabled=${this._loading}
					/>
					<button
						class="send"
						type="submit"
						}
						${ref(this.sendRef)}
						?disabled=${this._loading}
					>
						${this.send} ${this._loading ? linearBars : null}
					</button>
				</div>
				<div class="messages">
					${this._showSuccess
						? html`<span class="success">${this.success}</span>`
						: null}
					${this._showError
						? html`<span class="error">${this.error}</span>`
						: null}
				</div>
			</form>
		`;
	}
}

window.customElements.define('dnp-newsletter-block', DNPNewsLetterBlock);
