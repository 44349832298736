export const ProgressMixin = (superClass) =>
	class extends superClass {
		pauseProgress() {
			this._configureProgress();
			if (this._progress) {
				const current = this._progress.getCurrent();
				if (current) {
					current.style.animationPlayState = 'paused';
				}
			}
		}

		playProgress() {
			this._configureProgress();
			if (this._progress) {
				const current = this._progress.getCurrent();
				if (current) {
					current.style.animationPlayState = 'running';
				}
			}
		}

		_configureProgress() {
			if (!this._progress) {
				this._progress = this.shadowRoot.querySelector(
					'dnp-circular-progress'
				);
			}
		}
	};
