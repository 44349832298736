export const SliderMixin = (superClass) =>
	class extends superClass {
		shift(children, index) {
			const last = children.length - 1;
			if (index > last) {
				return 0;
			}
			return index < 0 ? last : index;
		}

		shiftToNext(children, index) {
			return this.shift(children, index + 1);
		}

		shiftToPrev(children, index) {
			return this.shift(children, index - 1);
		}

		proccess(children, index) {
			const count = children.length;

			Array.from(children).forEach((el, i) => {
				const center = Math.trunc(count / 2);
				const order = (count + center + i - index) % count;

				const zIndex = i === index ? 1 : -1;

				el.style.order = order;
				el.style.zIndex = zIndex;
				el.style.transition = 'none';

				el.classList.remove('dnp-fade-animation');
				el.classList.add('dnp-fade-hidden');

				const img = el.querySelector('figure[slot="media"] img');
				img?.classList.remove('dnp-circular-item__img--animate');

				const cover = el.querySelector('.dnp-circular-item__cover');
				const link = el.querySelector('.dnp-circular-item__title-link');

				cover?.classList.remove('dnp-cover-animation');
				link?.classList.remove('dnp-title-animation');

				if (index === i) {
					this.currentImage = img;
					this.currentItem = el;
					img?.classList.add('dnp-circular-item__img--animate');

					el.classList.remove('dnp-fade-hidden');
					el.classList.add('dnp-fade-animation');

					cover?.classList.add('dnp-cover-animation');
					link?.classList.add('dnp-title-animation');
				}
			});
		}

		pauseAnimation() {
			if (this.currentImage) {
				this.currentImage.style.animationPlayState = 'paused';
			}
		}

		playAnimation() {
			if (this.currentImage && this.currentItem) {
				this.currentImage.style.animationPlayState = 'running';
				this.currentItem.controls.playing = true;
			}
		}
	};
